import Vue from "vue";
import VueRouter from "vue-router";
import AboutUs from "../views/AboutUs/index.vue";

Vue.use(VueRouter);

const routes = [
    {
      path: "/",
      name: "AboutUs",
      component: AboutUs,
    },
    {
      path: '/index',
      name: 'index',
      component: resolve => require(['../views/AboutUs/index.vue'],resolve)
    },
    {
      path: "/management",
      name: "management",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/AboutUs/details/management.vue"
        ),
    },
    {
      path: "/Business",
      name: "Business",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/OurOffering/index"),
    },
    {
      path: "/assetManagement",
      name: "assetManagement",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/OurOffering/details/assetManagement.vue"
        ),
    },
    {
      path: "/investmentBanking",
      name: "investmentBanking",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/OurOffering/details/investmentBanking.vue"
        ),
    },
    {
      path: "/fintech",
      name: "fintech",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/OurOffering/details/fintech.vue"
        ),
    },
    {
      path: "/securitiesBrokerage",
      name: "securitiesBrokerage",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/OurOffering/details/securitiesBrokerage.vue"
        ),
    },
    {
      path: "/research",
      name: "research",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/OurOffering/details/research.vue"
        ),
    },
    {
      path: "/Career",
      name: "Career",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Career/index.vue"),
    },
    {
      path: "/personalIntroduction",
      name: "personalIntroduction",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/Career/details/personalIntroduction.vue"
        ),
    },
    {
      path: "/ContactUs",
      name: "ContactUs",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/ContactUs/index.vue"),
    },
    {
      path: "/statementOrPolicy",
      name: "statementOrPolicy",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "../views/statementOrPolicy/index.vue"
        ),
    },
    {
      path: "/Media",
      name: "Media",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Media/index.vue"),
    },
  ];
  
  const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(savedPosition);
          }, 500);
        });
      } else {
        return { x: 0, y: 0 };
      }
    },
  });



export default router