import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueI18n from 'vue-i18n'
// import 'lib-flexible'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/common.scss'
// import './assets/css/mixin.scss'
import './js/rem.js'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import { cn } from './assets/i18n/cn.js'
import { en } from './assets/i18n/en.js'

Vue.use(ElementUI)
Vue.use(VueI18n)

Vue.config.productionTip = false
const i18n = new VueI18n({
  locale: 'en', // 设置语言
  messages: {
    cn: { ...cn },
    en: { ...en }
  }
})

router.beforeEach((to, from, next) => {    
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0
  window.scrollTo(0,0)
  next()   // 要调用next 否则不生效
})

window.scrollTo({left: 0,top:0 })
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
  
})





new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
