<template>
  <div class="box">
    <div class="about-us-wrap">
      <div class="content" ref="scrollbox" id="scrollBox">
        <div class="image-top">
          <img src="@/assets/image/aboutUs/banner_about_us_v2.png" />
        </div>
        <div class="top2">
          <div class="top2-title">HKSFC Licence</div>
          <div class="top2-img">
            <img src="@/assets/image/aboutUs/icon_SFC.png" />
          </div>
        </div>
        <div class="top-list">
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <div class="item-img"><img :src="item.image" /></div>
            <div class="item-title">{{ item.title }}</div>
          </div>
        </div>
        <div class="content">
          <div class="content-img">
            <img src="@/assets/image/aboutUs/img_logo.png" />
          </div>
          <div class="content-title">
            FUTEC Financial Group was established in 2016. In just 6 years, we
            have grown and developed into a full-scale investment bank,
            providing customers with a one-stop professional financial services
            platform with services including Hong Kong and U.S. listing
            sponsorship and underwriting, bond underwriting, compliance
            advisory, financial advisory, structured finance, AI fintech trading
            platform 1690STOCK, research and asset management.
          </div>
        </div>
        <div class="bottom-title">BUSINESS HIGHLIGHTS</div>
        <div class="bottom">
          <div class="item" v-for="(item, index) in bottomDate" :key="index">
            <div class="item-img"><img :src="item.image" /></div>
            <div class="item-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "AboutUs",
  components: {
    bottom
  },
  data() {
    return {
      showDetails: false,
      isShowAnimation: false,
      isShowAnimation2: false,
      isShow: false,
      isSHowManagement: true,
      dataList: [
        {
          image: require("../../assets/image/aboutUs/icon_no1.png"),
          title: "Dealing in Securities"
        },
        {
          image: require("../../assets/image/aboutUs/icon_no2.png"),
          title: "Dealing in Futures Contracts"
        },
        {
          image: require("../../assets/image/aboutUs/icon_no4.png"),
          title: "Advising on Securities"
        },
        {
          image: require("../../assets/image/aboutUs/icon_no6.png"),
          title: "Advising on Corporate Finance"
        },
        {
          image: require("../../assets/image/aboutUs/icon_no9.png"),
          title: "Asset Management"
        }
      ],
      bottomDate: [
        {
          image: require("../../assets/image/aboutUs/icon_BUSINESS_HIGHLIGHTS_1.png"),
          text:
            "Participated in about 100 bond underwriting projects, with a total underwriting amount of more than US$20 billion"
        },
        {
          image: require("../../assets/image/aboutUs/icon_BUSINESS_HIGHLIGHTS_2.png"),
          text:
            "Participated in more than 10 Hong Kong and U.S. lPOs and equity financing projects"
        },
        {
          image: require("../../assets/image/aboutUs/icon_BUSINESS_HIGHLIGHTS_3.png"),
          text:
            "FUTEC International Bond Fund delivered returns of over 30% in 2020"
        },
        {
          image: require("../../assets/image/aboutUs/icon_BUSINESS_HIGHLIGHTS_4.png"),
          text: "Research report 100% hit rate in 2020"
        },
        {
          image: require("../../assets/image/aboutUs/icon_BUSINESS_HIGHLIGHTS_5.png"),
          text:
            "FUTEC International Bond Fund delivered returns of over 30% in 2020"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    senters(item) {
      item.state = false;
      this.isSHowManagement = false;
    },
    leaver(item) {
      item.state = true;
      this.isSHowManagement = true;
    },
    handleScroll(e) {
      if (e.target.scrollTop > 400) {
        this.isShowAnimation = true;
      }
      if (e.target.scrollTop > 1400) {
        this.isShowAnimation2 = true;
      }
    },
    sentersCulture() {
      this.isShow = true;
    },
    leaverCulture() {
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  // width: 62.5vw;
  // margin: auto;
  // background: #fff;
  // box-shadow: 8px 0 10px 0px rgba(0, 0, 0, 0.1) inset;
  // height: 2.5vh;
  .about-us-wrap {
    margin: auto;
    // background: #fff;
    // min-width: 1200px !important;
    .content {
      overflow-y: auto;
      .image-top {
        width: 1231px;
        height: 534px;
        margin: 60px auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .top2 {
        display: flex; 
        margin-left: 344px;
        .top2-title {
          font-size: 32px;
          font-weight: bold;
          color: #2b5473;
        }
        .top2-img {
          width: 109px;
          height: 67px;
          display: flex;
          margin-left: 20px;
          margin-top: -10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .top-list {
        display: flex;
        justify-content: space-between;
        margin: 50px 340px 0;
        .item {
          .item-img {
            display: flex;
            width: 47px;
            height: 47px;
            margin: auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .item-title {
            font-size: 18px;
            color: #00497b;
            margin-top: 25px;
          }
        }
      }
      .content {
        display: flex;
        justify-content: center;
        margin: 104px 340px 140px;
        .content-img {
          display: flex;
          width: 342px;
          height: 71px;
          margin: auto;
          // img {
          //   width: 100%;
          //   height: 100%;
          //   object-fit: contain;
          // }
        }
        .content-title {
          // width: 863px;
          // height: 161px;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          line-height: 36px;
          text-align: left;
          margin-left: 40px;
        }
      }
      .bottom-title {
        font-size: 32px;
        font-weight: bold;
        color: #00aadd;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        margin: 0 340px;
        .item-img {
          width: 111px;
          height: 95px;
          display: flex;
          justify-content: center;
          margin: 50px auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .item-text {
          width: 218px;
          font-size: 16px;
          color: #034777;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
