<template>
  <div class="content">
    <router-view />
  </div>
</template>
<script>
import bottom from "@/components/bottom.vue";
export default {
  name: "contents",
  components: {
    bottom
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  width: 100%;
  background: #fff;
  height: calc(100%);
  overflow: hidden;
  box-shadow: 0px 0 10px 0px rgba(0, 0, 0, 0.1) inset;
  .content-box {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
