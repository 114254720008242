<template>
  <div id="tabBar">
    <header class="tabBar-conter d-flex flex-y-center">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="menu-h">
        <el-menu
          :default-active="activeMenu"
          class="menu-list hidden-sm-and-down"
          mode="horizontal"
          @select="menuSelectHandler"
          v-for="(item, index) in $t('head.tabData')"
          :key="index"
          @mouseenter.native="senters(index)"
          @mouseleave.native="leaver(index)"
        >
          <el-menu-item :index="item.name">{{ item.tab }}</el-menu-item>
        </el-menu>
      </div>
      <div class="font-size">
        <!-- <span>简</span> | <span>繁</span> | -->
        <!-- <span style="color:#004877;">EN</span> -->
      </div>
    </header>
    <div
      class="list about-us"
      v-if="isShowAboutUsList"
      @mouseenter="sentersAboutUs()"
      @mouseleave="leaverAboutUs()"
    >
      <div class="triangle-up"></div>
      <div @click="menuSelectHandler('management')">Management</div>
    </div>
    <div
      class="list"
      v-if="isShow"
      @mouseenter="sentersList()"
      @mouseleave="leaver()"
    >
      <div class="triangle-up"></div>
      <div @click="menuSelectHandler('fintech')">
        1690 STOCK FINTECH
      </div>
      <div @click="menuSelectHandler('securitiesBrokerage')">FIXED INCOME</div>
      <div @click="menuSelectHandler('assetManagement')">ASSET MANAGEMENT</div>
      <div @click="menuSelectHandler('research')">RESEARCH</div>
      <div @click="menuSelectHandler('investmentBanking')">
        INVESTMENT BANKING
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "heads",
  data() {
    return {
      isShow: false,
      isShowAboutUsList: false
    };
  },
  computed: {
    activeMenu() {
      if (
        this.$route.path === "/assetManagement" ||
        this.$route.path === "/investmentBanking" ||
        this.$route.path === "/fintech" ||
        this.$route.path === "/securitiesBrokerage" ||
        this.$route.path === "/research"
      ) {
        return "Business";
      } else if (
        this.$route.path === "/Career" ||
        this.$route.path === "/personalIntroduction"
      ) {
        return "Career";
      } else {
        return this.$route.path.replace("/", "");
      }
    }
  },
  methods: {
    menuSelectHandler(index) {
      this.$router.push(index).catch(err => {
        err;
      });
      if (index === "Business") {
        this.isShow = !this.isShow;
      } else if (index === "index") {
        this.isShowAboutUsList = !this.isShowAboutUsList;
      } else {
        this.isShow = false;
        this.isShowAboutUsList = false;
      }
    },
    senters(index) {
      if (index === 1) {
        this.isShow = true;
        this.isShowAboutUsList = false;
      } else if (index === 0) {
        this.isShow = false;
        this.isShowAboutUsList = true;
      }
    },
    leaver() {
      this.isShow = false;
      this.isShowAboutUsList = false;
    },
    sentersList() {
      this.isShow = true;
      this.isShowAboutUsList = false;
    },
    sentersAboutUs() {
      this.isShowAboutUsList = true;
      this.isShow = false;
    },
    leaverAboutUs() {
      this.isShowAboutUsList = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#tabBar {
  color: #666666;
  font-size: 20px;
  // width: 100vw;
  // width: 1920px;
  width: 100%;
  height: 110px;
  background: #ffffff;
  position: relative;
  .tabBar-conter {
    justify-content: center;
  }
  .logo {
    // margin: auto 162px 32px auto;
    margin-right: 162px;
    width: 199px;
    height: 42px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .menu-h {
    display: flex;
    .el-menu.el-menu--horizontal {
      margin-right: 30px;
      border-bottom: 0px solid #fff;
      .el-menu-item {
        height: 110px;
        line-height: 110px;
        font-size: 22px !important;
        color: #666666;
        &.is-active {
          color: #ff9e18;
          border-bottom: 0px solid #fff;
        }
      }
    }
  }
  .font-size {
    font-size: 20px;
    margin-left: 20px;
  }
  .list {
    position: absolute;
    // left: 45%;
    left: 47%;
    top: 79%;
    z-index: 99;
    width: 330px;
    height: 335px;
    line-height: 60px;
    text-align: center;
    font-size: 22px;
    color: #666666;
    background: #f5f5f5;
    border-radius: 5px;
    filter: drop-shadow(0 0 6px #ccc);
    div:nth-child(2) {
      margin-top: 20px;
    }
    div:hover {
      color: #ff9e18;
    }
    .triangle-up {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #f5f5f5;
      position: absolute;
      top: -10px;
      left: 15%;
      margin-left: -10px;
    }
  }
  .about-us {
    // left: 36%;
    left: 38%;
    height: 110px;
  }
}
</style>
