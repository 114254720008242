export const en = {
    head: {
        tabData: [{
                tab: 'About Us',
                name: 'index',

            },
            {
                tab: 'Business',
                name: 'Business'
            },
            {
                tab: 'Career',
                name: 'Career'
            },
            {
                tab: 'Media',
                name: 'Media'
            },
            {
                tab: 'Contact Us',
                name: 'ContactUs'
            }
        ],
    },
    aboutUs: {
        milestones: 'MILESTONES',
        management: 'MANAGEMENT',
        corporateCulture: 'CORPORATE CULTURE',
        text: 'We are committed to doing the best for our clients, employees and society.',
        milestonesList: [{
                year: 2016,
                list: [{
                    title: 'Establishment',
                    text: []
                }]
            },
            {
                year: 2017,
                list: [{
                    title: 'Q2-Q3',
                    text: ['Licensed by the Securities and Futures Commission:Dealing in Securities Advising on Securities Asset Management Advising on Corporate Finance Dealing in Futures Contracts']
                }]
            },
            {
                year: 2019,
                list: [{
                        title: 'Q1',
                        text: ['Completed the first IPO project', 'Completed the first M&A project']
                    },
                    {
                        title: 'Q4',
                        text: ['Completed >30 USD-denominated corporate bonds issuance, issuance size ~US$10 billion',
                            'Ranked [Top 20%] on the Hong Kong Stock Exchange in terms of securities transaction volume'
                        ]
                    }
                ]
            },
            {
                year: 2020,
                list: [{
                        title: 'Q1',
                        text: ['FUTEC High Yield Bond Fund launched']
                    },
                    {
                        title: 'Q4',
                        text: ['Ranked 22 on the Investment Bank League Table of underwriting of USD-denominated corporate bonds (Chinese Real Estate industry), the only investment bank of Greater Bay Area in Top 30',
                            'Ranked [Top 20%] on the Hong Kong Stock Exchange in terms of securities transaction volume'
                        ]
                    }
                ]
            },
            {
                year: 2021,
                list: [{
                    title: 'Q1',
                    text: ['First direct investment project exited with 310% return']
                }]
            }
        ],
        managementList: [{
                userImage: require('@/assets/image/aboutUs/img_sam_1.png'),
                userImage2: require('@/assets/image/aboutUs/img_sam_2.png'),
                position: 'Chairman',
                name: 'Sam Lau',
                icon: require('@/assets/image/aboutUs/icon_about_us_in.png'),
                text: 'Graduate of Boston University, Chairman of Qianhai Heung Kong Financial Holding Group,Director of the Shenzhen Longgang Guo An County Bank, Committee Member of the All-China Youth Federation, Vice Chairman of the Youth Committee of the All-China Federation of Returned Overseas Chinese, Chairman of the Youth Committee of the Harmony Club and Director of the HeungKong Charitable Foundation. Sam has worked in international investment banking at Deutsche Bank AG, Hong Kong Branch and focuses on bringing together financial resources and business opportunities and developing cross-industry financial products.',
                state: true,
                index: 0
            },
            {
                userImage: require('@/assets/image/aboutUs/img_tony.png'),
                userImage2: require('@/assets/image/aboutUs/img_tony_2.png'),
                position: 'CEO',
                name: 'Tony Yau',
                icon: require('@/assets/image/aboutUs/icon_about_us_in.png'),
                text: 'Having over 20 years of experience in nternational capital markets, focusing on corporate finance and mergers and acquisitions in the Asian-Pacific region. As Managing Director of APAC at Deutsche Bank AG and Assistant Vice President at BNP Paribas, Tony’s team led the capital market by completing a series of landmark transactions.In 2003, Tony was responsible for the  establishment of the first foreign invested securities firm in the PRC for BNP Paribas - Changjiang BNP Paribas Peregrine Securities Ltd.',
                state: true,
                index: 1
            }
        ],
        corporateCultureList: [{
                image: require('@/assets/image/aboutUs/icon_about_us_con3_01.png'),
                title: "Exceeding Client's Expectation Every Day",
                text: 'We are a young yet experienced platform which provides tailor-made services with efficiency and flexibility. We are devoted to adding value to our clients and creating win-win situation for every stakeholder. We regard our clients as business partners and aim to establish long-term relationship.'
            },
            {
                image: require('@/assets/image/aboutUs/icon_about_us_con3_02.png'),
                title: 'Developing People',
                text: 'We believe that people are the greatest asset. By offering an appealing place to work and creating an open environment, our employees are encouraged to pursue personal and professional development in line with their potential. We collaborate and work as a team, together we can go further.'
            },
            {
                image: require('@/assets/image/aboutUs/icon_about_us_con3_03.png'),
                title: 'Doing the right thing',
                text: 'We uphold integrity. We are also aware of our environment and particularly care about our community. We are devoted to contributing to a fair financial market as well as an inclusive society.'
            }
        ]
    },
    OurOffering: {
        ourOfferingData: [{
                icon: require('@/assets/image/OurOffering/icon_our_offering_con1_03.png'),
                title: 'FINTECH',
                path: 'fintech'
            },
            {
                icon: require('@/assets/image/OurOffering/icon_our_offering_con1_05.png'),
                title: 'RESEARCH',
                path: 'research'
            },
            {
                icon: require('@/assets/image/OurOffering/icon_our_offering_con1_02.png'),
                title: 'INVESTMENT BANKING',
                path: 'investmentBanking'
            },
            {
                icon: require('@/assets/image/OurOffering/icon_our_offering_con1_04.png'),
                title: 'FIXED INCOME',
                path: 'securitiesBrokerage'
            },
            {
                icon: require('@/assets/image/OurOffering/icon_our_offering_con1_01.png'),
                title: 'ASSET MANAGEMENT',
                path: 'assetManagement'
            },
        ],
        assetManagementData: [{
                icon: require("@/assets/image/OurOffering/icon_our_offering_con2_01.png"),
                title: 'Structured Financing',
                text: 'FUTEC is well resourced and in a financially strong position to provide clients with premium financial products and services and to assist with not only their financing needs but the operational challenges they face.'
            },
            {
                icon: require("@/assets/image/OurOffering/icon_our_offering_con2_02.png"),
                title: 'Strategic Equity Investment',
                text: 'FUTEC selects enterprises with potential and engages in direct investment with focus on retail, consumption, healthcare and technology sectors.'
            }
        ],
        investmentBankingData: [{
                icon: require('@/assets/image/OurOffering/iocn_out_offfering_03_con2_01.png'),
                text: 'In-depth, industry-specfic expertise'
            },
            {
                icon: require('@/assets/image/OurOffering/iocn_out_offfering_03_con2_02.png'),
                text: 'Excellent Execution'
            },
            {
                icon: require('@/assets/image/OurOffering/iocn_out_offfering_03_con2_03.png'),
                text: 'To build trusted, long-term relationship'
            }
        ],
        stepList: [{
                indexs: 1,
                name: 'Corporate Finance advisory'
            },
            {
                indexs: 2,
                name: 'Merger & Acquisitions'
            },
            {
                indexs: 3,
                name: 'Equity Capital Market'
            }
        ],
        setpDetails: [{
                icon: require("@/assets/image/OurOffering/iocn_out_offfering_03_con4_01.png"),
                title: 'CORPORATE FINANCE ADVISORY',
                text: ['Our corporate finance experts advise clients on various corporate finance activities such as IPO, capital structure, M&A structuring, strategic partnership, liquidity management, cost of capital, value drivers, etc.',
                    'We are one of the most active Hong Kong financial advisory group with Type 6 SFC license in Hong Kong. Our role mainly include:'
                ],
                text1: ['acting as financial adviser to Hong Kong public listed companies, major shareholders and investors of these companies and parties seeking to control or invest in listed companies (mostly in transactions which involve the Hong Kong Listing Rules and/or Takeovers Code)',
                    'acting as independent financial adviser to independent board committees and/or independent shareholders of listed companies',
                    'acting as sponsor to clients pursuing an Hong Kong IPO',
                    'acting as compliance adviser, for listed companies in Hong Kong'
                ]
            },
            {
                icon: require("@/assets/image/OurOffering/iocn_out_offfering_03_con4_02.png"),
                title: 'MERGER AND ACQUISITIONS',
                text: ['Our M&A department advise corporations and institutions of all sizes on their most complex strategic needs, devises and executes innovative, customized solutions to solve their most challenging issues.',
                    'We excel in domestic and international transactions including acquisitions, divestitures, mergers, joint ventures, corporate restructurings, recapitalizations, spin-offs, exchange offers, leveraged buyouts and takeover defenses as well as shareholder relations.',
                    'We apply extensive experience with global industries, regions and financial products to meet our clients‘ short- and long-term strategic objectives.',
                    'Clients benefit from bespoke solutions combining: '
                ],
                text1: ['In-depth knowledge of sector and market dynamics with M&A bankers',
                    'Innovative advice on valuation, transaction structures and deal tactics/negotiations',
                    'Rigorous execution delivered with responsive and agile service',
                    'Comprehensive financing through our debt and equity issuance platforms'
                ]
            },
            {
                icon: require("@/assets/image/OurOffering/iocn_out_offfering_03_con4_03.png"),
                title: 'EQUITY CAPITAL MARKET',
                text: ['Our ECM team, in partnership with our corporate finance and M&A groups, enable us to serve our clients holistically. We offer a wide range of services, from origination to structuring, executing and syndicating financing for clients.',
                    'FUTEC is widely recognized as a fast growing regional players in capital raising, combining superior origination strength and structuring expertise with excellent distribution capability. The firm’s underwriting activities range from initial public offerings to follow-on equity issues, and from public transactions and private placements for wealthy nations and emerging markets.',
                    'FUTEC’s origination capabilities include:'
                ],
                text1: ['Initial Public Offerings',
                    'Convertible Issues',
                    'Private Placements'
                ]
            }
        ],
        fintechData: [{
            icon: require("@/assets/image/OurOffering/icon_our_offering_05_03.png"),
            name: 'IPOS'
        }, {
            icon: require("@/assets/image/OurOffering/icon_our_offering_05_04.png"),
            name: 'ACCOUNT OPENING'
        }, {
            icon: require("@/assets/image/OurOffering/icon_our_offering_05_05.png"),
            name: 'MARKET NEWS'
        }, {
            icon: require("@/assets/image/OurOffering/icon_our_offering_05_06.png"),
            name: 'CUSTOMER SERVICE'
        }],
        catalogueData: [{
                name: 'Account Opening Documents >',
                pdfData: [{
                        name: 'SLAO01 Account Opening Documents - Individual/Joint',
                        http: 'http://www.futec.com/Forms/SLAO01.pdf'
                    },
                    {
                        name: 'SLAO03 Client Agreenment',
                        http: 'http://www.futec.com/Forms/SLAO03.pdf'
                    },
                    {
                        name: 'SLAO06 Securities Trading Fees and Other Services Charges',
                        http: 'http://www.futec.com/Forms/SLAO06.pdf'
                    },
                    {
                        name: 'SLAO07 Real Time Quotation Service Agreement',
                        http: 'http://www.futec.com/Forms/SLAO07.pdf'
                    },
                    {
                        name: 'SLAO08 Professional Investor Assessment Form',
                        http: 'http://www.futec.com/Forms/SLAO08.pdf'
                    }
                    // {
                    //     name: 'SLAO12 Fund Deposit Withdrawal Information',
                    //     http: 'http://www.futec.com/Forms/SLAO12.pdf'
                    // }
                ]
            },
            // {
            //     name: 'Account Opening Documents - Corporate >',
            //     pdfData: [{
            //             name: 'SLAO00B Account Opening Documents - Corporate',
            //             http: 'http://www.futec.com/Forms/SLAO00B.pdf'
            //         },
            //         {
            //             name: 'SLAO06 Securities Trading Fees and Other Services Charges',
            //             http: 'http://www.futec.com/Forms/SLAO06.pdf'
            //         },
            //         {
            //             name: 'SLAO07 Real Time Quotation Service Agreement',
            //             http: 'http://www.futec.com/Forms/SLAO07.pdf'
            //         },
            //         {
            //             name: 'SLAO08 Professional Investor Assessment Form',
            //             http: 'http://www.futec.com/Forms/SLAO08.pdf'
            //         },
            //         {
            //             name: 'SLAO12 Fund Deposit Withdrawal',
            //             http: 'http://www.futec.com/Forms/SLAO12.pdf'
            //         }
            //     ]
            // },
            {
                name: 'Other Forms for customer service >',
                pdfData: [{
                        name: 'SLIP01 IPO Application Form',
                        http: 'http://www.futec.com/Forms/SLIP01.pdf'
                    },
                    // {
                    //     name: 'SLAO12 Fund Deposit Withdrawal Information',
                    //     http: 'http://www.futec.com/Forms/SLAO12.pdf'
                    // },
                    {
                        name: 'SLAO13 Third Party Authorisation Form',
                        http: 'http://www.futec.com/Forms/SLAO13.pdf'
                    },
                    {
                        name: 'SLSE01 Fund Withdrawal Instruction Form',
                        http: 'http://www.futec.com/Forms/SLSE01.pdf'
                    },
                    {
                        name: 'SLSE02 Securities Settlement Instruction Form',
                        http: 'http://www.futec.com/Forms/SLSE02.pdf'
                    },
                    {
                        name: 'SLSE03 Physical Securities Deposit Instruction Form',
                        http: 'http://www.futec.com/Forms/SLSE03.pdf'
                    },
                    {
                        name: 'SLSE04 Physical Securities Withdrawal Instruction Form',
                        http: 'http://www.futec.com/Forms/SLSE04.pdf'
                    },
                    {
                        name: 'SLSE06 Currency Conversion Instruction Form',
                        http: 'http://www.futec.com/Forms/SLSE06.pdf'
                    },
                    {
                        name: 'SLAD01 Change of Information and Other Services Application Form - Individual Joint Account',
                        http: 'http://www.futec.com/Forms/SLAD01.pdf'
                    },
                    {
                        name: 'SLAD02 Change of Information and Other Services Application Form - Corporate Account',
                        http: 'http://www.futec.com/Forms/SLAD02.pdf'
                    },
                    {
                        name: 'REG0001 Standard form of transfer',
                        http: 'http://www.futec.com/Forms/REG0001.pdf'
                    }
                ]
            },
            {
                name: 'Cyber Security Information >',
                pdfData: [{
                        name: 'Cyber Security Tips - July 2021',
                        http: 'http://www.futec.com/Forms/cyber202107.htm'
                    },
                    {
                        name: 'Cyber Security Tips - January 2021',
                        http: 'http://www.futec.com/Forms/cyber202101.htm'
                    }
                    // {
                    //     name: '2020年4月 網絡及資訊保安通訊',
                    //     http: 'http://www.heungkongfinancial.com/admin/Uploads/documents/CyberSecurity/hkfcs202004.pdf'
                    // },
                    // {
                    //     name: '2019年冬季 網絡及資訊保安通訊',
                    //     http: 'http://www.heungkongfinancial.com/admin/Uploads/documents/CyberSecurity/hkfcs2019d.pdf'
                    // },
                    // {
                    //     name: '2019年秋季 網絡及資訊保安通訊',
                    //     http: 'http://www.heungkongfinancial.com/admin/Uploads/documents/CyberSecurity/hkfcs2019c.pdf'
                    // },
                    // {
                    //     name: '2019年夏季 網絡及資訊保安通訊',
                    //     http: 'http://www.heungkongfinancial.com/admin/Uploads/documents/CyberSecurity/hkfcs2019b.pdf'
                    // },
                    // {
                    //     name: '2019年春季 網絡及資訊保安通訊',
                    //     http: 'http://www.heungkongfinancial.com/admin/Uploads/documents/CyberSecurity/hkfcs2019a.pdf'
                    // }
                ]
            }
        ],
        fintechTitle: 'FINTECH & BROKERAGE INTRODUCTION',
        fintechText: 'We possess a strong commitment to integrate the advantages of information technology with our brokerage services to help investors capture investment opportunities easily. Leveraging myriads fintech elements to redefine the trading experience, our 1690 Stock application provides a one-stop online investing platform to offer our users instant access to the market.',
        fintechTextData: [{
            title: 'Key features of our app include:',
            data: ['Seamless, efficient and secured account opening process', ' Easy-to-use interface with fast-growing community and exclusive events', 'Latest updates and investment focus to help investors understand market movements', ' In-depth fundamental research insights supported by FUTEC' + "'" + 's in-house research']
        }],
        securitiesBrokerageData: [{
                icon: require('@/assets/image/OurOffering/icon_our_offering_04_01.png'),
                title: 'DEBT CAPITAL MARKET',
                text: [{
                        title: 'Serving corporate and institutional clients, FUTEC has a very strong presence in the global credit markets. The firm combines superior debt origination and structuring expertise with exceptional distribution capabilities to a large base of investors, including sophisticated hedge funds, global asset management companies, corporate, family offices, and ultra-high net worth individuals.'
                    },
                    {
                        title: 'The origination team works directly with issuers that seek funding. FUTEC advises these clients on debt financing strategies, including issuance of corporate bonds.'
                    }
                ],
                // image: require('@/assets/image/img_our_offering.png')
            },
            // {
            //     icon: require('@/assets/image/resources.png'),
            //     title: 'SELECTED EXPERIENCE',
            //     resources: true
            //     // image: require('@/assets/image/img_our_offering.png')
            // },
            {
                icon: require("@/assets/image/OurOffering/icon_our_offering_04_02.png"),
                title: 'FUTEC BOND FUND',
                text: [{
                        title: 'Diversified investment to capture excess return:',
                        text: ['Well diversifies portfolio in various sectors and issuers, which reduces risks compared with fundsthat have concentrated investment in severa  issuers;',
                            '• Actively find alpha in lower-rated or lesser known bonds;',
                            '• Short duration to reduce interest rate risk.'
                        ]
                    },
                    {
                        title: 'Growing investment opportunities in USD bond but low default risk: ',
                        text: ['• Growing market size: Record of Asia-ex Japan USD Bond Issuance in 2019, increasing 27% to 308 bn USD;',
                            '• Lower default risk, estimated 2% default risk in the following 2 years. '
                        ]
                    },
                    {
                        title: 'Professional investment and research team: ',
                        text: ['• FUTEC and HeungKong have close relationship with issuers, especially the ones located in Greater Bay Area, thereby securing allocation in primary as an anchor, receive first hand information and increase return of the fund; ',
                            '• Our teams have an average of over 10-year related industry experience and make right investment decisions to protect investors’ rights under turbulent global investment environment.'
                        ]
                    }
                ]
            },
            {
                icon: require('@/assets/image/OurOffering/icon_our_offering_04_03.png'),
                title: 'STRUCTURED FINANCING',
                text: [{
                    title: 'FUTEC provides structured financing to companies to help them achieve objectives such as making an acquisition, business expansion, liquidity management, effecting a buy-out, repurchasing shares or funding a one-time dividend or investment.'
                }]
            }
        ],
        research: {
            title: 'FUTEC Research, with its core focus on Greater China, delivers high quality company specific, sector and economic insights and reports to our clients worldwide. Our areas of expertise include but are not limited to: Consumer, Education, Healthcare and Real Estate. We are committed to maintaining the highest level of independence and objectivity in our insight and reports.',
            text: [{
                title: 'Our key research products include:',
                text: ['Local research and investment recommendation reports (HK&US listed)', 'Monthly sector reports', 'Trading opportunity notes']
            }]
        }
    },
    Career: {
        careerData: [{
            title: 'SEARCH JOBS'
        }, {
            title: 'WHY FUTEC?'
        }, {
            title: 'MEET US'
        }],
        jobData: ['Corporate Finance', 'Fixed Income', 'Global Capital Market', 'Brokerage', 'Asset Management', 'Research', 'Fintech', 'Support Division'],
        Search: 'Search for jobs in',
        whyHKFTextA: 'FUTEC Financial is one of the leading investment banks in the Greater Bay Area. We empower our people to learn and thrive.',
        whyHKFTextB: 'At FUTEC Financial, you will get access to a diverse pool of opportunities, resources and networks that fuels your career progression.',
        whyHKFTextC: 'We embrace changes through relentless focus on innovation and value creation for better future.',
        CarmanName: 'Carman',
        CarmanIntroduce: 'My name is Carman, and I graduated from The Chinese University of Hong Kong with a degree in Business',
        CarmanIntroduceA: 'My name is Carman, and I graduated from The Chinese University of Hong Kong with a degree in Business Administration. I joined Futec Financial when I was in my penultimate year of study back in the summer of 2019 for the one-month internship program, in which I was given the chance to have a taste of what it is like to work in a boutique investment bank. And currently I am with the Corporate Finance division as an Analyst in Futec Financial. ',
        CarmanIntroduceB: 'My internship experience was spent in the Corporate Finance and Fintech Divisions, this is a unique combination to any of the traditional internships offered elsewhere. As a university student without any finance experience before, my key is to gain an insight of the fundamental knowledges and the working culture of the investment banking industry, and this is what exactly this program had offered. ',
        CarmanIntroduceC: 'The warm and welcoming atmosphere that I received during my time in here is another factor that inspired me to take the next step in my career – coming back as a graduated Analyst. Futec is fill with a round of highly experienced and professional bankers who are all incredibly knowledgeable, supportive and energetic which generally a joy to be around. More importantly, you will always have the chance to learn, growth and improve as they provide with tremendous amount of support and always willing to teach and share. Hence, I found that these opportunities were among the most valuable and rewarding aspects of my experience. ',
        CarmanIntroduceD: 'Another key unique factor of this program is its diversification. What differentiate this internship from others is it offers participants a chance to understand the working models of the innovative aspect of the industry, which is Fintech, along with the traditional side of the industry - the investment banking area. By offering this flexibility, it allows us to truly explore our interest within this continuously changing industry and the potential future career path. ',
        CarmanIntroduceE: 'To sum up my internship in Futec, I would say it offers a comprehensive, dynamic and fruitful experience. If you are still in doubt on which career path you should take or simply just want to “feel” whether the investment bank working culture is the right fit for you, then the internship program in Futec would be a wonderful opportunity for you. ',
        CarmanIntroduceF: 'Lastly, I would encourage anyone who are eager to equip the ability to work on needle-moving transactions in a dynamic environment, to learn as much about the business world as possible, and more importantly to work with motivated and interesting people to join us! ',
        more: 'more',
        workCultureTitle: 'Life at FUTEC Financial',
        workCultureTextA: 'With our unique culture of collaboration, we offer a supportive workplace environment that fuels persistent growth of our people.',
        workCultureTextB: 'We are looking for people who aspire to bring changes and contribute to the sustainable growth of FUTEC Financial.',
        ChloeWongName: 'Chloe Wong',
        ChloeWongIntroduce: 'My name is Chloe Wong and I major in Quantitative Finance in The University of Hong Kong.',
        ChloeWongIntroduceA: 'My name is Chloe Wong and I major in Quantitative Finance in The University of Hong Kong. I completed the Summer internship programme from HKSI in FUTEC Financial from 15 July 2021 to 12 July 2021.',
        ChloeWongIntroduceB: 'The HKSI Summer internship lasted for four weeks Despite such short period of internship, I was offered the opportunity to be involved in a live deal specifically related to an IPO project in HK. During the four weeks, I was primarily engaged in sponsor-related work, including financial due diligence and proof-reading of application proof etc. Such hands-on experience is indeed very precious opportunity to me, particularly further broadening my horizon in the corporate finance world.',
        ChloeWongIntroduceC: 'Besides, I enjoyed very much working with my colleagues in FUTEC Financial. The working environment is very encouraging and people are always willing to share knowledge whenever I am in doubt. The learning & discussion process was very inspiring in such a way that it helps shaping my career development goals.',
        ChloeWongIntroduceD: 'I will recommend this opportunity to those who aspire to build their career in corporate finance.',
        SeanKanName: 'Sean Kan',
        SeanKanIntroduce: 'My name is Sean Kan and I major in Risk Management and Business Intelligence in Hong Kong University.',
        SeanKanIntroduceA: 'My name is Sean Kan and I major in Risk Management and Business Intelligence in Hong Kong University of Science and Technology. I completed the internship in FUTEC Financial from 16 Nov 2020 to 29 June 2021. ',
        SeanKanIntroduceB: 'This internship was very engaging, through which I had the opportunity to work with professionals in the corporate finance and bonds trading space. The team is very well experienced and always willing to share their experience with me throughout the internship. Through this internship, I have equipped myself with more insights about my career development. ',
        SeanKanIntroduceC: 'Investment banking is a very competitive industry. Solid practical experience is very important in helping ones to gain understanding about the industry. Joining FUTEC Financial offers valuable experience for students to have a glimpse on the investment banking industry. Hence, I will recommend this opportunity to people who are interested in developing their career in this space.'
    },
    ContactUs: {
        title: 'CONTACT US',
        text: 'Thank you for your interest in FUTEC Financial'
    },
    statementOrPolicy: {
        statementOrPolicyData: ['Disclaimer', 'Privacy policy', 'Risk disclosure'],
        DisclaimerData: [{
                title: 'General disclaimers and other information'
            },
            {
                title: 'Use of Site',
                text: 'The information provided on this site is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject HeungKong Securities Limited ("HKSL"), its holding company, fellow subsidiaries, associates or other affiliates ("the HKF Group") to any regulatory requirement within such jurisdiction or country. Any person or entity who intends to respond to or rely upon the information on this site must satisfy himself that he is not subject to any local requirement which restricts or prohibits him from doing so.'
            },
            {
                title: 'No Offer',
                text: 'Neither the information nor any opinions contained in this site constitutes a solicitation or offer by the HKF Group to buy or sell, whether as principal or agent, any securities, futures, options or other financial instruments/products or provide any service or investment advice.'
            },
            {
                title: 'Disclosure of interests',
                text: 'Members of the HKF Group, their directors and/or representatives and/or employees and/or family members and/or associates may have a long or short position in any of the securities or other financial instruments/products mentioned in the site at any time and may make a purchase and/or sale, or offer to make a purchase and/or sale and/or otherwise deal in any of the said securities or other financial instruments/products from time to time in the open market or otherwise, in each case either as principal or agent.'
            },
            {
                title: 'No Warranty',
                text: 'Although the information on this site is obtained or compiled from sources we believe to be reliable, the HKF Group gives no express or implied warranties or representations (including, without limitation, any warranties or representations as to accuracy, validity, timeliness or completeness of any such information, merchantability or fitness for a particular purpose, security, non-infringement of third party rights or freedom from computer viruses or other harmful components), and disclaims any liability of whatsoever nature, in connection with or arising out of any of the Information or any part or function of this site or any reliance on or access to or use of, or any inability to access or use, any of the information or any part or function of this site, even if any relevant notice may have been given to the HKF Group. All information or other services (if any) on this site is provided on an "as is" and "as available" basis, and is subject to change without prior notice.'
            },
            {
                text: 'All quotes or quotations available on this site do not constitute any commitment on the part of the HKF Group.'
            },
            {
                title: 'Use of electronic media',
                text: 'The internet or other electronic media (including, without limitation, this site, electronic devices, services of third party telecom service providers such as mobile phones or other handheld trading devices or interactive voice response systems) is/are inherently unreliable medium/media of communication and that such unreliability is beyond the HKF Group' + 's control. Such unreliability may give rise to various consequences e.g. it may result in failure or delay in transmission of any communication or information or affect any function available on this site or the timeliness, sequence, accuracy, validity, adequacy or completeness of any communication or information or cause loss, or loss of confidentiality, of any communication or information. The aforesaid is not an exhaustive list of all consequences from such unreliability. The HKF Group shall not be responsible for any loss, damage, cost, expenses, claim or liability of whatsoever nature, directly or indirectly, arising out of or in connection with such unreliability or the public nature of the internet or other electronic media or any other cause beyond the HKF Group' + 's control (including, without limitation, a breakdown or failure of transmission of communication facilities).'
            },
            {
                text: 'Any software required for the use of any part of this site may be governed by a license agreement and you must comply with the terms and conditions of it. You are solely responsible for your breach of any such term or condition. The HKF Group does not accept any liability whatsoever as a result of your downloading or using, in any other way, such software.'
            },
            {
                title: 'Limitation of Liability',
                text: 'In no event will any member of the HKF Group be liable or have any responsibility for any loss or damage of any kind, whether direct, indirect, special, consequential or incidental, resulting from access or use of, or inability to access or use, this site or any sites or pages linked to this site, including (without limitation) damages resulting from the act or omission of any third party, even if any member of the HKF Group has been advised of or should have been aware of the possibility thereof.'
            },
            {
                title: 'Indemnity',
                text: 'You shall on demand indemnify and keep indemnified the HKF Group from and against all liabilities, claims, costs, expenses and damages of any kind which may be reasonably suffered or incurred by the HKF Group, directly or indirectly, arising out of or in connection with your access to and/or use of this site (or any part thereof) and/or the information contained therein (or any part of it) and/or the reliance and/or acting on by the HKF Group any information, data, message or communication from you or purportedly from you or your breach of any provision in these Terms of Use.'
            },
            {
                title: 'Use of Links',
                text: 'Should the viewer leave this site via a link contained herein, and view content that is not provided by the HKF Group, the viewer does so at its own risk. The HKF Group is not responsible for any loss or damage of any kind caused by any delays, defects or omissions that may exist in the services, information or other content provided in such site, whether actual, alleged, consequential or punitive. The HKF Group makes no guarantees or representations as to, and shall have no liability for, any electronic content delivered by any third party or have any responsibility with respect to, including without limitation, the accuracy, subject matter, quality or timeliness of any electronic content.'
            },
            {
                title: 'Copyright',
                text: 'The information on this site may not be reproduced, distributed or published in any medium for any purpose without prior express written consent from the HKF Group. If you download any information or software from this site, you agree that you will not copy it or remove or obscure any copyright or other notices or legends contained in any such information.'
            },
            {
                title: 'Important',
                text: 'By accessing this site and any of its pages you agree to the terms and conditions set out above and to any additional terms and conditions applicable to any specific part or content of this site.'
            },
            {
                text: 'In no event will any member of the HKF Group be liable or have any responsibility for any loss or damage of any kind, whether direct, indirect, special, consequential or incidental, resulting from access or use of, or inability to access or use, this site or any sites or pages linked to this site, including (without limitation) damages resulting from the act or omission of any third party, even if any member of the HKF Group has been advised of or should have been aware of the possibility thereof. '
            },
            {
                text: 'The HKF Group may, at any time and from time to time, at its absolute discretion, without notice and without giving any reason therefor, make any changes to the the information contained in its sites (or any part thereof) and/or decline your access to and/or use of such sites. The HKF Group shall accept no liability in connection with or arising out of any such change or declination. '
            },
            {
                text: 'These terms and conditions are available in English and Chinese. If there is an inconsistency between the English and Chinese versions, the English version shall prevail.'
            }
        ],
        PrivacyPolicyData: [{
                text: 'In order to provide services and to comply with regulatory requirements, HeungKong Financial Group limited (“HKFG”) collects certain personal, nonpublic information from you. This includes information:'
            },
            {
                list: ['provided during the account application process (e.g. your name, email address, telephone number, date of birth, investment objectives, etc.);', 'acquired as a result of the transactions you conduct through the HeungKong Securities Limited or in connection with services offered by HKFG;', 'received from consumer-reporting agencies;', 'collected through Internet "cookies”.']
            },
            {
                text: 'Cookies are bits of textual information that are sent electronically from a web server to your browser and are stored on your computer.  They do not identify you individually or contain personal information about you, unless you have identified yourself or provided the information by, for example, opening an account or registering for an online service.  HKFG may use cookies to measure and identify website traffic patterns and to track the performance of web features and advertisements.  By providing HKFG with a better understanding of how you and others use HKFG’s website and other web services, cookies enable HKFG to improve the navigation and functionality of its website and to present you with the most useful information and offers.  HKFG may share information obtained from cookies with its employees, agents, and affiliates, but does not sell such information to unaffiliated third parties.  HKFG may permit other companies or their third-party advertisement servers to set cookies on your browser when you visit HKFG’s website.  We also use cookies to improve the performance of our advertising on our website.'
            },
            {
                text: 'We do not disclose personal, nonpublic information to individuals or entities that are not affiliated with HKFG, except as provided by law.  For example, among other reasons, we may disclose or report such information: where necessary to authorize, effect, administer or enforce transactions that you request or authorize; to maintain and administer your account; to provide you with account confirmations, statements and records; to maintain appropriate archival records; where we believe that disclosure is required by applicable law, rules or regulations; to cooperate with law enforcement, regulatory or self-regulatory organizations; in connection with resolving disputes arising from your transactions or services we provide to you; to enforce our customer and other agreements; to meet our obligations; or to protect our rights and property.  As long as consistent with applicable securities laws and regulations, we may share anonymized account information or anonymized delayed order information with third parties (and/or share such information among our affiliates) for the purpose of analysis, research, market data compilation, product creation, establishing order routing and execution relationships, or for any other lawful purpose.'
            },
            {
                text: 'If you wish to access a copy of your personal data or you wish to modify any personal data which we hold about you, or you want us to cease processing any of your personal data held by us, subject to applicable law, you can do so by written request to contact set out below.'
            },
            {
                text: 'In the case of a request for access to personal data, we reserve the right to charge an appropriate fee, if applicable.  You may be required to supply a valid means of identification as a security precaution to assist us in preventing the unauthorized disclosure of your personal data.  We will process your request within the time provided by applicable law.'
            }, {
                data: ['Compliance Department', 'HeungKong Financial Group Limited', 'Suite 622, Ocean Centre, Harbour City', 'Tsim Sha Tsui, Kowloon', 'Hong Kong', 'Tel: (852) 3972 0765', 'Fax: (852) 3972 0730']
            }
        ],
        RiskDisclosureData: [{
                title: 'RISK DISCLOSURE STATEMENTS'
            },
            {
                text: 'The price and income of any securities or other financial instruments/products described in this site may fall as well as rise. Past performance should not be taken as an indication of future performance.'
            },
            {
                text: 'These disclosure statements do not intend to disclose or discuss all the risks and other important information of any securities or other financial instruments/products mentioned in this website. You must assess your own risk exposure. Before entering into any transaction in relation to any securities or other financial instruments/products mentioned in this website, you should consult, depending on the circumstances, your own legal, tax, financial and other professional investors.'
            }
        ]
    },
    Media: {
        title: 'MEDIA'
    }
}