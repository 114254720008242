<template>
  <div id="app">
    <heads class="heads"></heads>
    <router-view class="app-content" />
  </div>
</template>

<script>
import heads from "@/components/head.vue";
import contents from "@/components/content.vue";

export default {
  name: "Home",
  components: {
    heads,
    contents
  },
  mounted() {
    document.oncontextmenu = new Function("event.returnValue=false");
    document.onselectstart = new Function("event.returnValue=false");
  },
  methods: {}
};
</script>

<style lang="scss">
@import "./themes/index.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .app-content {
    height: 90vh;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    box-shadow: 0px 0 10px 0px rgba(0, 0, 0, 0.1) inset;
  }
  .app-content::-webkit-scrollbar {
    display: none;
  }
}
</style>
